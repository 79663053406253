html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: black;
    background: white;
  }
}

.fc-daygrid-event{
  display: block!important;
  padding-left: 15px!important;
  pointer-events: none;
  white-space: normal !important;
  align-items: normal !important;
}
.fc-daygrid-event-dot{
  display: inline-flex;
  position: absolute;
  left: 0px;
  top: 6px;
}
.fc-event-time,.fc-event-title{
  display: inline;
}
